<template>
  <div class="cfa-root">
    <c-f-a-nav>
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3">
          <h1 class="cfa-section-title">{{ $t('dashboard_title') }}</h1>
        </div>
      </template>
      <template v-slot:content>
        <div class="cfa-page">
          <!-- row1 -->
          <div class="row d-flex flex-wrap">
            <div class="col-12 col-md-3 p-0">
              <div class="bg-white d-flex m-2 py-3 gBlock">
                <div class="p-3 gFolder-container">
                  <img width="30" src="../../assets/Icons/All_Icon/Vector-3.png" alt="dashboard state image"/>
                </div>
                <div>
                  <p>12</p>
                  <p>{{ $t('all_module') }}</p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-3 p-0">
              <div class="bg-white gBlock d-flex m-2 py-3">
                <div class="p-3 gFolder-container">
                  <img width="30" src="../../assets/Icons/All_Icon/Vector-4.png" alt="dashboard state image"/>
                </div>
                <div>
                  <p>03</p>
                  <p>{{ $t('module_done') }}</p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-3 p-0">
              <div class="bg-white gBlock d-flex m-2 py-3">
                <div class="p-3 gFolder-container">
                  <img width="25" src="../../assets/Icons/All_Icon/Group1000004437.png" alt="dashboard state image"/>
                </div>
                <div>
                  <p>05</p>
                  <p>{{ $t('quiz_done') }}</p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-3 p-0">
              <div class="bg-white gBlock d-flex m-2 py-3">
                <div class="p-3 gFolder-container">
                  <img width="30" src="../../assets/Icons/All_Icon/Group1000004435.png" alt="dashboard state image"/>
                </div>
                <div>
                  <p>80%</p>
                  <p>{{ $t('all_quiz') }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- col1 -->
<!--          <div class="d-flex flex-column flex-md-row mt-3">-->
<!--            <div class="col-12 col-md-8 pe-0 pe-md-2">-->
<!--              <div class="cfa-welcome ml-0">-->
<!--                <div class="cfa-welcome-video">-->
<!--                  <c-f-a-video :showLabel="false"></c-f-a-video>-->
<!--                </div>-->
<!--                <div class="cfa-welcome-text mt-3">-->
<!--                  <h1 style="font-size: 1.5em;" class="d-flex align-items-center mb-2 mt-2">-->
<!--                    <img width="35" src="@/assets/img/gif/waving_hand.gif" alt="greeting gif"/>&nbsp;-->
<!--                    <span class="font-weight-bold position-relative top-1">{{ $t('welcome') }} Malick</span>-->
<!--                  </h1>-->
<!--                  <p style="color: #a9a9a9;">-->
<!--                    Dans votre espace de sensibilisation à la cybersécurité CyberForce Academy, vous aurez accès à <span class="cfa-primary">12 modules</span> détaillants un panorama complet pour une active cyber-résilience. L’ensemble des modules durent environ 2 heures. A la fin des 12 modules vous passerez le <span class="cfa-primary">quiz final</span> pour l’obtention de votre certification.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="cfa-welcome ml-0 mt-4">-->
<!--                <div class="cfa-welcome-text mt-3">-->
<!--                  <p style="color: #a9a9a9;">-->
<!--                    <span class="cfa-primary fs-5">Important</span>: {{ $t('deadline_text') }} <strong>10/52/87</strong>.-->
<!--                  </p>-->
<!--                </div>-->
<!--                <dead-line :day="30" :month="12" :year="2022"></dead-line>-->
<!--                <div style="height: 22.5em; overflow:hidden;">-->
<!--                  <div class='cfa-alert-d d-flex justify-content-between align-items-center py-3'>-->
<!--                    <p>consectetur adipisicing elit. A qui aut totam vel, molestiae </p>-->
<!--                    <button class="btn cfa-btn-primary">UPDATE</button>-->
<!--                  </div>-->
<!--                  <div class='cfa-alert-d d-flex flex-column justify-content-between align-items-center py-5'>-->
<!--                    <img width="100" src="../../assets/img/svg/cfa-ud-assets-05.svg" alt="cfa default image for no notification"/>-->
<!--                    <p class="mt-5 text-center">😥️ {{ $t('no_update_label') }} </p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="ml-0 mt-4">-->
<!--                <certificate-preview></certificate-preview>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="col-12 col-md-4 ps-0 ps-md-2 pt-4 pt-md-0">-->
<!--              <div class="cfa-welcome py-3">-->
<!--                <a href="/mon-certificat#detail-de-progression" style="text-decoration: none;">-->
<!--                  <div class="d-flex align-items-center justify-content-between cfa-state-view cfa-bg-primary py-2 px-4 mb-2">-->
<!--                    <div class="d-flex justify-content-start align-items-center">-->
<!--                      <div>-->
<!--                        <DoughnutChart-->
<!--                            :classValue="'chartTextStyle'"-->
<!--                            :width="80"-->
<!--                            :height="80"-->
<!--                            :percent="5"-->
<!--                            :visibleValue="true"-->
<!--                            foregroundColor="rgb(3, 57, 60)"-->
<!--                            backgroundColor="#b9ebec"-->
<!--                            :strokeWidth="15"-->
<!--                        />-->
<!--                      </div>-->
<!--                      <div class="ms-2">-->
<!--                        <h5>{{ $t('progress1') }}</h5>-->
<!--                        <p>5 Leçons</p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      &lt;!&ndash;<i class="fa fa-chevron-right"></i>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->

<!--                <a href="/quiz-final" style="text-decoration: none;">-->
<!--                  <div class="d-flex align-items-center justify-content-between cfa-state-view  cfa-bg-primary py-2 px-4 mb-2">-->
<!--                    <div class="d-flex justify-content-start align-items-center">-->
<!--                      <div>-->
<!--                        <DoughnutChart-->
<!--                            :classValue="'chartTextStyle2'"-->
<!--                            :width="80"-->
<!--                            :height="80"-->
<!--                            :percent=28-->
<!--                            :visibleValue="true"-->
<!--                            foregroundColor="rgb(3, 57, 60)"-->
<!--                            backgroundColor="#b9ebec"-->
<!--                            :strokeWidth="15"-->
<!--                        />-->
<!--                      </div>-->
<!--                      <div class="ms-2">-->
<!--                        <h5>{{ $t('progress2') }}</h5>-->
<!--                        <p>5 Leçons</p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      &lt;!&ndash;<i class="fa fa-chevron-right"></i>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->

<!--                <a href="/rendez-vous-expert" style="text-decoration: none;">-->
<!--                  <div class="d-flex align-items-center justify-content-between cfa-state-view  cfa-bg-primary py-2 px-4">-->
<!--                    <div class="d-flex justify-content-start align-items-center">-->
<!--                      <div>-->
<!--                        <DoughnutChart-->
<!--                            :classValue="'chartTextStyle2'"-->
<!--                            :width="80"-->
<!--                            :height="80"-->
<!--                            time="yes"-->
<!--                            :percent="12"-->
<!--                            :visibleValue="true"-->
<!--                            foregroundColor="rgb(3, 57, 60)"-->
<!--                            backgroundColor="#b9ebec"-->
<!--                            :strokeWidth="15"-->
<!--                        />-->
<!--                      </div>-->
<!--                      <div class="ms-2">-->
<!--                        <h5>{{ $t('progress3') }}</h5>-->
<!--                        <p>Total: 80 minutes</p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      &lt;!&ndash;<i class="fa fa-chevron-right"></i>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->
<!--              </div>-->

<!--              <div class="cfa-welcome py-3 mt-3">-->
<!--                <div>-->
<!--                  <strong>{{ $t('progress4') }}: <br>(3 modules / 12)</strong>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <progress-bar moduleName="Module 1" :percent="100" label="100%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 2" :percent="100" label="100%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 3" :percent="20" label="20%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 4" :percent="0" label="0%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 5" :percent="0" label="0%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 6" :percent="0" label="0%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 7" :percent="0" label="0%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 8" :percent="0" label="0%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 9" :percent="0" label="0%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 10" :percent="0" label="0%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 11" :percent="0" label="0%" style="padding-top: 1em;"></progress-bar>-->
<!--                  <progress-bar moduleName="Module 12" :percent="0" label="0%" style="padding-top: 1em;"></progress-bar>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
// import CFAVideo from '@/components/shared/video/CFAVideo.vue'
// import CertificatePreview from '@/components/shared/certificate/CertificatePreview.vue'
// import DoughnutChart from '@/components/shared/chart/DoughnutChart.vue'
// import ProgressBar from '@/components/shared/chart/ProgressBar.vue'
// import DeadLine from '@/components/shared/DeadLine.vue'

export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav,
    // CFAVideo,
    // CertificatePreview,
    // DoughnutChart,
    // ProgressBar,
    // DeadLine
  }
}
</script>

<style scoped>
.cfa-page{

}
.gFolder-container{
  background-color: #cef1f3;
  border-radius: 0.5em;
  height: 4.3em;
  width: 4.6em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gBlock{
  align-items: center;
  justify-content: start;
  border-left: 4px solid #25c2cb;
  border-radius: 0.6em;
  padding-left: 2em;
  padding-right: 2em;
  height: 8em !important;
}
.gBlock div:last-of-type p{
  margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
  font-weight: bold;
  font-size: 2em;
}
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  /* margin: .5em; */
}

.cfa-alert-d{
  border-bottom: 1px solid #f3f3f3;
  transition: all ease-in .3s;
}
.cfa-alert-d:last-of-type{
  border: none;
}
.cfa-alert-d:hover{
  -webkit-box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
  box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
}
.cfa-state-view{
  border-radius: .5em;
}
.cfa-state-view:hover{
  background-color: #f7f8fc;
  color: #000;
}
</style>
