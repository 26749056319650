import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

/* JQUERY SETUP IN VUE APP */
import jQuery from 'jquery'
window.$ = window.jQuery = jQuery;

/* BOOSTRAP AND CSS UTILS */
import 'popper.js'
import 'bootstrap'
import './assets/css/app.css'
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import DataTable from 'datatables.net-vue3'
import DataTablesLib from 'datatables.net-bs4';
import 'datatables.net-select-bs4';

DataTable.use(DataTablesLib);
//in main.js
import 'primevue/resources/themes/aura-light-green/theme.css'
import 'datatables.net'
import 'datatables.net-dt'
import 'datatables.net-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.html5.js'

import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-select'
import 'datatables.net-select-bs4'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import PrimeVue from 'primevue/config';
/* MULTILINGUAL APP CONFIG */
import { createI18n } from 'vue-i18n'
import messages from './lang'
export const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages
})

/* CALENDAR COMPONENTS */
// import Calendar from '@pigsking/vue-mark-calendar'

// import 'https://cdn.datatables.net/1.13.2/css/jquery.dataTables.min.css';
// import 'https://cdn.datatables.net/select/1.6.0/css/select.dataTables.min.css';

createApp(App).use(router).use(i18n).use(vueMultiSelect).use(PrimeVue).mount('#app')

const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers.common['Authorization'] = token
}
