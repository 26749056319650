<template>
    <div class="cfa-root">
      <c-f-a-nav :alert="alertString" contentClass="position-relative">
        <template v-slot:title>
          <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
            <h1 class="cfa-section-title">Micro-Learning</h1>
            <div>
              <button @click="this.newModule=true" class="btn cfa-btn-primary me-2">
                CRÉER UN NOUVEAU MICRO-APPRENTISSAGE <i class="fa fa-plus">&nbsp;</i>
              </button>
            </div>
          </div>
        </template>
        <template v-slot:content>
          <div v-show="!waitingAPIResponse" class="cfa-page">
            <div>
              <content-filter @viewMode="getViewMode" @viewFilter="getViewFilter" :total1="totalModule" :total2="totalInProgress"></content-filter>
            </div>
            <div v-if="this.allModules.length == 0" class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
              <div class="d-flex flex-column">
                <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 15em;"/>
                <span class="mt-3 fs-5 fw-bold">Oups ! Rien pour le moment.</span>
              </div>
            </div>
            <div v-else>
              <div class="row pt-3">
                <course-card  v-for="module, i in this.allModules" v-bind:key="i" @update="getAllModule" :data="module"  :id="module.id" :type="module.title"  :preview="module.cover_image" :label="'Micro Learning '+(i+1)" :started="(i!=0) ? false : true" :title="module.micro_learning_url" :description="module.description" :max__horaire="module.duration" :viewMode='displayMode' :filterMode="filterMode"   @sync="getAllModule" :uid="'cfa-crud-module-'+i"></course-card>
              </div>
            </div>
          </div>
          <div v-show="waitingAPIResponse" class="cfa-page">
            <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
              <div class="preloader_center d-flex flex-column">
                <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
                <p class="pl-3">loading . . .</p>
              </div>
            </div>
          </div>
        </template>
      </c-f-a-nav>
      <!--footer-->
      <c-f-a-footer></c-f-a-footer>
     
      <!-- create module -->
      <scalable-modal @callback="addNewMicroLearning" :break="exitModuleModal" label="AJOUTER UN MODULE" uid="cfa-modal-003" @close="newModule=false" :open="newModule" :advancedControl="true" validationButtonText="Créer ce module" exitButtonText="Annuler" :style="'width: 75%; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
        <template v-slot:content>
          <!-- blo1 -->
          <div class="row w-100">
            <div class="col-8">
              <!--<div class="mt-2">
                <modern-input @inputChange="(e)=>{this.connect_email = e}" placeholder="Votre Adresse email" errorText="Champ requis" uid="cfa-username-bloc" type="email" css></modern-input>
              </div>-->
              <div class="form-group py-2 pe-0 pe-md-2 ">
                  <p class="mb-2"><strong>TITRE</strong></p>
                  <div class="d-flex flex-row">
                    <input v-model="module_title" class="form-control" type="text" placeholder="entrez le titre">
                  </div>
                </div>
              <div class="mt-2">
                <p class="mb-2"><strong>DESRIPTION</strong></p>
                <textarea v-model="module_description" class="descriptionArea"></textarea>
              </div>
            </div>
            <div class="col-4 pe-0">
              <div>
                <div class="form-group py-2 pe-0 pe-md-2 ">
                  <p class="mb-2"><strong>MICRO LEARNING URL</strong></p>
                  <div class="d-flex flex-row">
                    <input v-model="module_microlearning_url" class="form-control" type="text" placeholder="entrez l'URL du micro-apprentissage">
                  </div>
                </div>
                <div class="form-group py-3 pe-0 pe-md-2 ">
                  <p class="mb-2"><strong>DURÉE</strong></p>
                  <div class="d-flex flex-row">
                    <input v-model="module_time" class="form-control" type="number" placeholder="(seconds)">
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>IMAGE DE COUVERTURE</strong></p>
              <image-input @image-loaded="onImageLoaded"/>
            </div>
          </div>
  
          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>MESSAGE E-MAIL PRINCIPAL</strong></p>
              <div class="d-flex flex-row">
                <input v-model="module_top_message" class="form-control" type="text" placeholder="entrez le premier e-mail">
              </div>
            </div>
          </div>
  
          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>MESSAGE E-MAIL EN BAS</strong></p>
              <div class="d-flex flex-row">
                <input v-model="module_bottom_message" class="form-control" type="text" placeholder="entrez le message électronique en bas">
              </div>
            </div>
          </div>

          <div class="row w-100 mt-3">
            <div class="form-group py-3 pe-0 pe-md-2 ">
              <p class="mb-2"><strong>SMS MESSAGE</strong></p>
              <div class="d-flex flex-row mb-2">
                <input v-model="module_sms_message" class="form-control" type="text" placeholder="saisir un SMS">
              </div>
            </div>
          </div>
          <div class="row w-100 mt-3">
            <div class="mt-2">
                <p class="mb-2"><strong>IM MESSAGE</strong></p>
                <textarea v-model="module_im_message" class="descriptionArea"></textarea>
              </div>
          </div>
        </template>
      </scalable-modal>
    </div>
  </template>
  
  <script>
  import CFAFooter from '@/components/nav/CFAFooter.vue'
  import CFANav from '@/components/nav/CFANav.vue'
  // import ContentFilter from '@/components/shared/ContentFilter.vue'
  import CourseCard from '@/components/shared/course/CourseCard3.vue'
  import { getAPI } from '@/axios-api.js'
  // import PreLoader from '@/components/shared/PreLoader.vue'
  import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
  // import ModernInput from '@/components/shared/input/ModernInput.vue'
  import ImageInput from '@/components/shared/input/ImageInput.vue'
  // import { Translate } from '@google-cloud/translate';

  
  export default {
    name: 'DashboardView',
    components: {
      CFAFooter,
      CFANav,
      CourseCard,
      ScalableModal,
      ImageInput,
      
    },
    data () {
      return {
        allModules: [],
        allTags: [],
        alertString: null,
        displayMode: 'GROUP',
        filterMode: 'ALL',
        alertMe: false,
        gAlertMessage: '',
        gAlertType: '',
        waitingAPIResponse: false,
        newModule: false,
        newTags: false,
        tag: null,
        exitTagModule: null,
        exitModuleModal: null,
        module_tagSelect1: '',
        module_tagSelect2: '',
        module_tagSelect3: '',
        module_target: '',
        module_content: '',
        module_video: '',
        module_title: '',
        module_bottom_message:'',
        module_description: '',
        module_sms_message:'',
        module_time: '',
        module_top_message:'',
        
        module_im_message:'',
        module_microlearning_url:'',
        module_image_cover: null,
        totalModule: 0,
        totalInProgress: 0,
      }
    },
    methods: {
      removeTag (e) {
        for (let i = 0; i < this.allTags.length; i++) {
          if (this.allTags[i].id === e) {
            this.allTags.splice(i, 1);
            break;
          }
        }
      },
      getViewMode (e) {
        this.displayMode = e
      },
      getViewFilter (e) {
        this.filterMode = e
      },
      publishModule (bool) {
        this.alertString = `Module ${(bool ? 'publié' : 'désactivé')} avec succès|alert-yes|4000::`+Date.now()
      },
      getAllModule () {
        this.waitingAPIResponse = true
        getAPI.get('micro_learning/courses/')
        .then(response => {
          if(response.status == 200) {
            let modulesData = response.data
            this.allModules = modulesData.results
            this.totalModule = modulesData.count
            // TODO: make this dynamic
            this.totalInProgress = 1
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0]
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
      },
      getAllTabs () {
        getAPI.get('tags')
            .then(response => {
              if(response.status == 200) {
                this.allTags = response.data.results
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
            .catch(error => {
              if (error.response) {
                let firstError = Object.values(error.response.data)[0][0]
                this.alertString = `${firstError}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
      },
      
       
      onImageLoaded (imageData) {
        this.module_image_cover = imageData
      },
      joinStringToArray (s1, s2, s3) {
        let array = []
        if(s1 != "")
          array.push(`${s1}`)
        if(s2 != "")
          array.push(`${s2}`)
        if(s3 != "")
          array.push(`${s3}`)
        return array;
      },
      addNewMicroLearning () {
        
            console.log(this.module_image_cover);
            const formData = new FormData();
              formData.append('cover_image', this.module_image_cover);
          getAPI.post('micro_learning/courses/', {
            title: this.module_title,
            description: this.module_description,
            micro_learning_url: this.module_microlearning_url,
            top_email_message: this.module_top_message,
            bottom_email_message: this.module_bottom_message,
            sms_message: this.module_sms_message,
            im_message: this.module_im_message,
            duration: this.module_time,
            
            
          }).then((response) => {
            if(response.status == 201) {
                const formData = new FormData();
            formData.append('cover_image', this.module_image_cover);
            getAPI.patch(`micro_learning/courses/${response.data.id}/`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(() => {
              this.getAllModule()
              let msg = 'Micro learning ajouté avec succès!'
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            })
          }
          this.exitModuleModal = Date.now()
          }).catch((error) => {
            if(error.response.status == 413) {
              this.alertString = `Oops ! Uploaded File Too Large|alert-no|10000::`+Date.now()
            }
            if (error.response) {
              let firstError = Object.values(error.response.data)[0][0].replace(' en ', " ")
              this.alertString = `${firstError}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitModuleModal = Date.now()
          });
        },
    },
   
    mounted () {
      this.getAllModule(),
      this.getAllTabs()
    }
  }
  </script>
  
  <style scoped>
  .tagUl{
    padding: 0;
  }
  .tagUl, .tagUl li{
    list-style-type: none;
  }
  .tag-bloc{
    min-height: 18em;
    width: calc(100% - 4em);
    background-color: red;
    /*background-color: #272f44;*/
    position: fixed;
    left: 2em;
    right: 2em;
    bottom: -14.5em;
  }
  .descriptionArea{
    height: 135px;
    width: 100%;
    position: relative;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    outline: currentcolor none medium !important;
    border: 1px solid rgb(204, 204, 204);
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
    padding: 6px 12px;
    background: rgb(247, 248, 252) none repeat scroll 0% 0%;
  }
  .uploadImageArea{
    width: 100%;
    height: 20em;
    background-color: #d7dde3;
  }
  </style>
  